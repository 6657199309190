
.hero-slider{
    background-image: url(../images/net4.jpg) ;
    position: relative;
    background-attachment: fixed;
    background-size: 1500px ;
    overflow: hidden;
    text-align: center;
    z-index: 1;
    background-blend-mode: multiply;
}
.hero-slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000029;
    z-index: -1;
}



.brand-logo{
    width: 100%;
    max-width: 70px;
    height: 35px;
}
.hero-slider{
    padding: 60px 0 20px  0;
}
.header-hero {
    position: relative;
    z-index: 5;
    background-position: bottom center;
    height: 800px;
  }
  
  @media only screen and (min-width: 1400px) {
    .header-hero {
      height:750px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hero {
      height: auto;
    }
  }
  
  @media (max-width: 767px) {
    .header-hero {
      height: auto;
    }
    .text-center.offset-ms-1.offset-md-2.offset-lg-2.col-lg-8.col-md-8.col-sm-10 {
margin: 0 auto;
    }
  }
   
  .header-hero-content {
    position: relative;
    z-index: 9;
    animation: slideInLeft 1s ease-out;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hero-content {
      padding-top: 150px;
    }
  }
  
  @media (max-width: 767px) {
    .header-hero-content {
      padding-top: 120px;
    }
  }
  
  .header-hero-content .header-title {
    font-size: 42px;
    color: #38424D;
    text-align: left;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-hero-content .header-title {
      font-size: 36px;
    }
  }
  
  @media (max-width: 767px) {
    .header-hero-content .header-title {
      font-size: 22px;
    }
    .col-md-9.col-sm-10 {
      text-align: center;
  }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-hero-content .header-title {
      font-size: 38px;
    }
  }
  
  .header-hero-content .header-title span {
    display: contents;
    color: #0898E7;
  }
  
  .header-hero-content .text {
    margin-top: 30px;
  }
  
  .header-hero-content ul {
    margin-top: 30px;
  }
  
  .header-hero-content ul li {
    margin-left: 50px;
  }
  
  .header-hero-content ul li:first-child {
    margin-left: 0;
  }
  
  .header-hero-content .main-btn {
    margin-top: 10px;
  }
  
  .header-hero-content .header-video {
    margin-top: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 50%;
    background-color: #0898E7;
    position: relative;
    z-index: 5;
  }
  
  .header-hero-content .header-video::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(8, 152, 231, 0.8);
    top: 0;
    left: 0;
  }
  
  .header-image {
    padding-top: 10px;
    position: relative;
    z-index: 5;
    animation: slideInRight 1s ease-out;
  }
  
  .header-image .image {
    width: 100%;
    border-radius: 50px;
    max-width: 400px;
    height: 600px;

  }
  @media (max-width: 800px) {

    .container {
      max-width: 100%;
  }
  }
  @media (max-width: 767px) {
    .header-image .image-shape {
      left: -90px;
    }

  }

  @media (max-width: 992px) {
    a.btn-get-started.me-2.animate__animated.animate__fadeInLeft.animate__delay-1s ,
    a.btn-get-started.animate__animated.animate__fadeInRight.animate__delay-1s {
      padding: 10px;
  }
}
  
  .header-image .image-shape img {
    max-width: 350px;
  }
  
  @media (max-width: 767px) {
    .header-image .image-shape img {
      max-width: 250px;
    }
  }
  
  @keyframes slideInRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  

  @media (max-width: 650px) {

    .row.align-items-center.justify-content-center.justify-content-lg-between {
      flex-direction: column;
      
  }
  .col-6 {
    max-width: 500px;
    width: 100%;
}
.col-4 {
  display: none;
}
.header-hero-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
.header-hero-content .header-title {
 
  text-align: center;
}
p.para{
  text-align: center !important;
}
  }


  



