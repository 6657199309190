.mv-container {
  background-color: #579fe2;
  color: #ffffff;
}

p.statement-title-color {
  color: #ffffff;
}

.statement-card h4 {
  margin-bottom: 1rem;
}

.justify-text {
  text-align: justify;
}
