#objectives {
  position: relative;
  overflow: hidden;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

#objectives::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: #579fe2;
  z-index: -1;
}

.objective-card {
  background: rgba(13, 110, 253, 0.25);
  border: none;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  /* border-radius: 2rem; */
}

.objective-card-body {
  padding: 0.5rem;
  background-color: rgb(255 255 255 / 73%);
  height: 300px;
}
.objective-card-body:hover{
  background-color: white;
}
.objective-card-body .objective-card-text {
  text-align: justify;
}

.objective-card:hover .img-container {
  padding: 0.2rem;
}

.objective-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color: #252525;
}
