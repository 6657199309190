.bg-theme-color {
    background-color: #579fe2;
    color: #ffffff;
  }
  
  .offer-card {  
    padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  height: 240px;
  background-color: #579fe2;
  /* box-shadow: 0 4px 6px rgba(85, 84, 84, 0.1), 0 -4px 6px rgba(85, 84, 84, 0.1), 4px 0 6px rgba(85, 84, 84, 0.1), -4px 0 6px rgba(85, 84, 84, 0.1); */
  border-radius: 30px;
  position: relative;
  margin-bottom: 70px;
display: flex;
justify-content: center;
  }
  
  .offer-card-body {
    background-color: white;
    padding: 16px;
    position: absolute;
    height: 180px;
    box-shadow: 0 4px 6px rgba(85, 84, 84, 0.1), 0 -4px 6px rgba(85, 84, 84, 0.1), 4px 0 6px rgba(85, 84, 84, 0.1), -4px 0 6px rgba(85, 84, 84, 0.1);
    max-width: 240px;
    bottom: -50px;
    border-radius: 50px;
}
  .offer-card-body .offer-card-text {
    text-align: justify;
  }
  
  .offer-card:hover .img-container {
    padding: 0.2rem;
  }
  
  .offer-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "Roboto", sans-serif;
    color:#252525;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 80px;
    column-gap: 50px;
}


  /* 
  contact us
  */
@media only screen and (max-width:767px){
    .animate__animated.animate__fadeInUp {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .offer-card {
    
      height: 155px;
    }
    .offer-card-body {
    
      height: 110px;
      max-width: 440px;
    }
}
@media only screen and (max-width:767px){
  .offer-card {
    
    height: 185px;
  }
  .offer-card-body {
    height: 150px;
    max-width: 300px;
}
}
@media only screen and (max-width:375px){
  .offer-card {
    
    height: 250px;
  }
  .offer-card-body {
    height: 180px;
    max-width: 250px;
}
}