.bg-theme-color {
  background-color: #579fe2;
  color: #ffffff;
}

.whychooseus-card {
  padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  height: 310px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(85, 84, 84, 0.1), 0 -4px 6px rgba(85, 84, 84, 0.1), 4px 0 6px rgba(85, 84, 84, 0.1), -4px 0 6px rgba(85, 84, 84, 0.1);
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whychooseus-card::before {
  content: attr(data-num);
  position: absolute;
  top: -4rem;
  left: 0rem;
  font-size: 5.5rem;
  font-weight: bold;
  color: #579fe285;
}
.whychooseus-card-body .objective-card-text {
  text-align: justify;
}

.whychooseus-card:hover .img-container {
  padding: 0.2rem;
}

.whychooseus-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color: #252525;
}

.col-lg-4.col-md-6.col-sm-12 {
  margin-bottom: 50px;
}
 @media only screen and (max-width:767px){
  .col-lg-4.col-md-6.col-sm-12 {
    margin-bottom: 50px;

}
.row{
 margin: 0 auto;
}
 }