.bg-theme-color {
  background-color: #579fe2;
  color: #ffffff;
}

.service-card {
  text-align: center;
  background-color: #ffffff; /* White background for the card */
  border: none;
  width: 100%;
  height: 435px;
  margin-bottom: 7rem;
  border: 1px solid white;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.service-card-body {
  padding: 10px 20px;
  text-align: center;
}

.service-card-body .service-card-text {
  text-align: justify;
  color: #666666; /* Softer text color for readability */
}

.img-container {
  height: 35px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem; /* Space between image and text */
}

.card-image {
  border-radius: 42rem;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
padding: 10px;
  object-fit: cover;
  background-color: #fff;
  position: absolute;
  top: -93px;
}

/* .service-card:hover .img-container .card-image {
  box-shadow: #579fe2 0px 3px 10px 0px; 
} */

.service-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color: #333333; /* Darker color for better contrast */
}

/* Responsive styles */
/* @media only screen and (max-width: 1400px) {
  .card-image {
    width: 45%;
  }
}

@media only screen and (max-width: 1200px) {
  .card-image {
    width: 55%;
  }
}

@media only screen and (max-width: 992px) {
  .card-image {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .card-image {
    width: 23%;
  }
}

@media only screen and (max-width: 600px) {
  .card-image {
    height: 170px;
    width: 170px;
  }

 
} */

@media screen and (max-width: 767px) and (min-width: 425px) {
  .service-card {
    height: 370px;
  }
}

@media screen and (max-width: 1399px) and (min-width: 992px) {
  .service-card {
    height: 580px;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .service-card {
    height: 500px;
  }
}
@media screen and (max-width: 425px) and (min-width: 380px) {
  .service-card {
    height: 440px;
  }
}
@media screen and (max-width: 380px) and (min-width: 280px) { 
   .service-card {
    height: 550px;
  }
}
@media only screen and (max-width: 768px)  { 
  .card-image {
font-size: 6rem;
    width: 140px;
}
}
.service-card:hover{
background-color: #579fe2;
color: white;
border: 2px solid white;
}